<template>
    <div class="login-container">
        <div class="the-login w-100">
            <div class="the-login__container rounded-lg d-flex flex-column m-auto">
                <img
                    class="the-login__logo"
                    src="@/assets/Xplora-logo-black.png"
                    alt="Xplora Logo"
                />
                <app-form
                    form-class="the-login__form"
                    :on-submit="onSubmit"
                    :needs-confirmation="false"
                    :show-title-divider="false"
                >
                    <template v-slot:fields>
                        <app-input
                            id="the-login__email"
                            :label="$t('email')"
                            type="email"
                            name="email"
                            :placeholder="$t('email')"
                            :is-valid="isValid"
                            :required="true"
                            :invalid-feedback="$t('login.passwordWrong')"
                            @update-value="(e) => (email = e)"
                        ></app-input>

                    </template>

                    <template v-slot:buttons>
                        <b-button
                            type="submit"
                            variant="primary"
                            class="w-100 the-login__button"
                            >{{ $t('forms.buttons.login') }}</b-button
                        >
                    </template>
                </app-form>
                <p style="font-size: 0.6rem; text-align: center">
                    Please enter your Email to recieve an Email to change your password
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import AppInput from '../components/ui/AppInput.vue';
    import AppForm from '../components/ui/AppForm.vue';

    export default {
        components: { AppInput, AppForm },
        name: 'ForgotPassword',
        data() {
            return {
                email: '',
                isValid: null,
            };
        },
        methods: {
            async onSubmit() {
                try {
                    // we need to add endpoint here
                    this.$router.push('/');
                } catch (e) {
                    this.isValid = false;
                }
            },
        },
    };
</script>
